<template>
  <b-overlay
      :show="seminars === null"
      rounded="lg"
  >
    <div v-if="seminars !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="seminars"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <!-- Column: price -->

          <template #cell(price)="data">

            <div class=" d-flex flex-row ">
              <span class="text-primary">{{makePrettyPrice(data.item.price)}} تومان</span>
            </div>

          </template>

          <!-- Column: startDate -->

          <template #cell(startDateTime)="data">

            <small class="">
              {{data.item.startDateTime.slice(11,16)}}
              -
              {{createJalaliDate(data.item.startDateTime.slice(0,10))}}
            </small>

          </template>

          <!-- Column: endDate -->

          <template #cell(endDateTime)="data">

            <small class="">
              {{data.item.endDateTime.slice(11,16)}}
              -
              {{createJalaliDate(data.item.endDateTime.slice(0,10))}}
            </small>

          </template>

          <!-- Column: createDate -->

          <template #cell(showTime)="data">

            <small class="">
              {{data.item.showTime.slice(11,16)}}
              -
              {{createJalaliDate(data.item.showTime.slice(0,10))}}
            </small>

          </template>

          <!-- Column: delete -->

          <template #cell(delete)="data">

            <div class="cursor-pointer d-flex flex-row"
                 v-b-modal.modal-delete
                 @click="showModal(data.item.seminarId)"
            >
              <feather-icon icon="TrashIcon" size="20" class="text-danger" />
            </div>

          </template>

          <template #cell(actions)="data">
            <b-link :to="{ name: 'apps-seminar-members', params: { id: data.item.seminarId }}">
              <feather-icon icon="ClipboardIcon" size="20"/>
            </b-link>
          </template>

          <template #cell(edit)="data">
            <b-link :to="{ name: 'apps-seminar-edit', params: { id: data.item.seminarId }}">
              <feather-icon icon="EditIcon" size="20"/>
            </b-link>
          </template>

          <template #cell(setReserve)="data">
            <b-link :to="{ name: 'apps-seminar-reserve', params: { id: data.item.seminarId }}">
              <feather-icon icon="UserPlusIcon" size="20"/>
            </b-link>
          </template>

          <template #cell(setPrice)="data">
            <b-link :to="{ name: 'apps-seminar-setPrice', params: { id: data.item.seminarId }}">
              <feather-icon class="text-success" icon="DollarSignIcon" size="20"/>
            </b-link>
          </template>


        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCount"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
    <b-modal
        id="modal-delete"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteSeminar(deleteItem)"
    >
      <span>حذف شود؟</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BOverlay,BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {CouponGetAllRequest} from "@/libs/Api/Coupon";
import {CouponDeleteRequest} from "@/libs/Api/Coupon";
import Helper from "@/libs/Helper";
import {SeminarDeleteRequest, SeminarGetAllRequest} from "@/libs/Api/Seminar";


export default {
  name: "SeminarList",
  title:"لیست سمینار ها - پنل ادمین مکس ",
  data(){
    return{
      seminars:null,
      totalCount:null,
      addNewCategory:false,
      currentPage:1,
      deleteItem:null,
      perPage:10,
      searchCommand:'',
      perPageOptions:[10, 20, 30 , 40 , 50],
      myTableColumns : [
        { key: 'title',label:'عنوان'},
        { key: 'owner',label:'برگزار کننده'},
        { key: 'startDateTime',label:'تاریخ و زمان شروع'},
        { key: 'endDateTime',label:'تاریخ و زمان پایان'},
        { key: 'price',label:'قیمت'},
        { key: 'showTime',label:'تاریخ ثبت نام'},
        { key: 'reservesCount',label:'تعداد صندلی های رزرو شده'},
        { key: 'actions',label:'شرکت کنندگان'},
        { key: 'setPrice',label:'قیمت گذاری'},
        { key: 'setReserve',label:'رزرو توسط ادمین'},
        { key: 'edit',label:'ویرایش'},
        { key: 'delete',label:'حذف'},
      ],
      defaultGetData:{
        pageNumber:1,
        count:10,
      },
    }
  },
  async created(){
    await this.getAllSeminars(this.defaultGetData.pageNumber,this.defaultGetData.count)
  },

  methods:{
    async getAllSeminars(pageNumber,count){
      let  _this = this;
      let  defaultGetData={
        pageNumber:pageNumber,
        count:count,
      }

      let seminarGetAllRequest = new SeminarGetAllRequest(_this);
      seminarGetAllRequest.setParams(defaultGetData);
      await seminarGetAllRequest.fetch(function (content){
        _this.seminars = content.seminars;
        _this.totalCount = content.seminarsCount;
      },function (error){
        console.log(error)
      })
    },
    async deleteSeminar(param){
      let _this = this;

      let seminarDeleteRequest = new SeminarDeleteRequest(_this);
      seminarDeleteRequest.setId(param);
      await seminarDeleteRequest.fetch(function (content){
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: ` حذف شد.`,
          },
        })
        _this.getAllSeminars(_this.defaultGetData.pageNumber,_this.defaultGetData.count)
      },function (error){
        console.log(error)
      })
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    makePrettyPrice(param){
      return Helper.prettyPrice(param,0)
    },
    showModal(param){
      let _this = this;
      _this.deleteItem = param
    },
  },
  watch:{
    currentPage:function (nv,ov) {
      this.getAllSeminars(nv,this.defaultGetData.count,this.defaultGetData.searchCommand);
    },
  },
  components:{
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,

    vSelect,
  },
}
</script>

<style scoped>

</style>