import WebServiceRequest from '../Api/WebServiceRequest'

class CouponCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Coupon/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class CouponDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('Coupon/Delete?id='+id)
    }
}
class CouponGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Coupon/GetAll');
    }
    setParams(data){
        super.setRequestParam(data);
    }
}
class CouponGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Coupon/Get')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class CouponGetUserByCouponIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('UserCoupon/GetUserByCouponId?couponId='+id)
    }
}
class CouponUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Coupon/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class CouponExecuteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setData(data){
        super.setTag('Coupon/CouponExecute?orderId='+data.orderId+'&code='+data.code)
    }
}
export {
    CouponCreateRequest,
    CouponDeleteRequest,
    CouponGetAllRequest,
    CouponGetRequest,
    CouponGetUserByCouponIdRequest,
    CouponUpdateRequest,
    CouponExecuteRequest
};
